.content {
	main {
		padding: 4rem 32px 2rem 32px;
		box-sizing: border-box;

		@media #{$break-md-up} {
			height: 100%;
			min-height: 100vh;
		}

		.main-inner {
			height: 100%;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}
	}
}
