.branding {
	width: 100%;
	height: auto;

	@media #{$break-md-up} {
		height: 100vh;
	}

	header {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 4rem 32px 4rem 32px;
		box-sizing: border-box;
		background-color: $white;

		@media #{$break-md-up} {
			width: 50%;
			position: fixed;
			top: 0;
			left: 0;
		}

		@media #{$break-lg-up} {
			padding: 3rem 48px;
		}

		.logo {
			width: 150px;

			svg {
				display: block;
				fill: $blue-md-dk;
			}
		}
	}
}
