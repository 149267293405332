html,
body {
	background-color: $cream-lt;
	overflow-x: hidden;
	text-size-adjust: none;
	-webkit-tap-highlight-color: transparent;
}

body {
	position: relative;
	padding: 0;
	font-family: $font-family;
	font-weight: $font-weight-medium;
	font-size: $font-size;
	color: $font-color;
	line-height: $line-height;
}

.flex {
	display: flex;
}

.columns {
	display: flex;
	flex-direction: column;

	@media #{$break-md-up} {
		flex-direction: row;
	}

	.column {
		position: relative;

		&.half {
			width: 100%;

			@media #{$break-md-up} {
				width: 50%;
			}
		}
	}
}

::selection {
	background-color: $yellow;
}
