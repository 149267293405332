footer {
	margin-top: 1rem;

	.fine-print {
		p {
			&.fine-print-text {
				margin-bottom: 0;
				font-size: 0.875rem;
			}
		}
	}
}
