h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: $font-family-heading;
	font-weight: $font-weight-regular;
	color: $font-color;
	text-transform: none;
	line-height: $line-height-heading;
	text-align: left;
}

h1 {
	font-size: 4rem;
}

h2 {
	font-size: 2.25rem;
}

h3 {
	font-size: $font-size;
}

h4,
h5,
h6 {
	font-size: $font-size;
}

p {
	margin: 0 0 1rem 0;
	font-size: 1.25rem;
}

b,
strong {
	font-weight: $font-weight-bold;
}

a {
	color: $font-color;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}
