.social {
	width: 100%;
	max-width: 275px;
	margin-top: 3rem;

	@media #{$break-lg-up} {
		max-width: none;
	}

	.columns {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: -0.75rem -12px;

		.column {
			&.social-link {
				margin: 0.75rem 12px;

				a {
					width: 56px;
					height: 56px;
					display: block;
					background-color: $cream-lt;
					border-radius: 100%;
					text-decoration: none;
					transition: all $transition-speed $transition-easing;

					.link-inner {
						width: 100%;
						height: 100%;
						align-items: center;
						justify-content: center;

						i {
							font-size: 2rem;
							color: $blue-md-dk;
						}
					}

					&:hover,
					&.focus-visible {
						background-color: $blue-md-dk;

						.link-inner {
							i {
								color: $cream-lt;
							}
						}
					}
				}
			}
		}
	}
}
