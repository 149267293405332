// MARINE SIKONA

// 400 - Regular

@font-face {
	font-display: swap;
	font-family: "Marine Sikona";
	font-style: normal;
	font-weight: 400;
	src: url("/fonts/marine-sikona/MarineSikona-Regular.woff") format("woff"),
		url("/fonts/marine-sikona/MarineSikona-Regular.woff2") format("woff2");
}

// MONTSERRAT

// 500 - Medium

@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: url("/fonts/montserrat/montserrat-v26-latin-500.woff") format("woff"),
		url("/fonts/montserrat/montserrat-v26-latin-500.woff2") format("woff2");
}

@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 500;
	src: url("/fonts/montserrat/montserrat-v26-latin-500italic.woff")
			format("woff"),
		url("/fonts/montserrat/montserrat-v26-latin-500italic.woff2")
			format("woff2");
}

// 700 - Bold

@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: url("/fonts/montserrat/montserrat-v26-latin-700.woff") format("woff"),
		url("/fonts/montserrat/montserrat-v26-latin-700.woff2") format("woff2");
}

@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: italic;
	font-weight: 700;
	src: url("/fonts/montserrat/montserrat-v26-latin-700italic.woff")
			format("woff"),
		url("/fonts/montserrat/montserrat-v26-latin-700italic.woff2")
			format("woff2");
}
