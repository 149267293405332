.accordion {
	.accordion-item {
		width: 100%;
		margin: 0;
		border-bottom: $line-width solid $font-color;

		.accordion-trigger {
			width: 100%;
			display: flex;
			padding: 2rem 0;
			background-color: transparent;
			border: none;
			cursor: pointer;

			.button-inner {
				width: 100%;
				align-items: center;

				.accordion-trigger-heading {
					padding-right: 32px;
				}

				.accordion-trigger-icon {
					margin-left: auto;

					i {
						font-size: 1.5rem;
						color: $font-color;
					}
				}
			}
		}

		.accordion-content {
			height: 0;
			margin: 0;
			overflow: hidden;
			transition: 0.25s;

			.accordion-content-inner {
				.acc-content-wrap {
					margin-bottom: 2rem;
				}
			}
		}

		&.is-active {
			.accordion-trigger {
				.accordion-trigger-icon {
					i {
						transform: rotate(180deg);
					}
				}
			}
		}

		&:first-of-type {
			.accordion-trigger {
				padding-top: 0;
			}
		}
	}
}
